:root {
  --subscribe-border: #efefef;
}

.subscribe-form .errors {
  color: red;
}

.subscriber-email {
  border-top-color: var(--subscribe-border);
  border-left-color: var(--subscribe-border);
  border-bottom-color: var(--subscribe-border);
}
.subscribe-button {
  border-top-color: var(--subscribe-border);
  border-right-color: var(--subscribe-border);
  border-bottom-color: var(--subscribe-border);
}

.img-dim {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  right: 50%;
  width: 100vw !important;
  position: relative !important;
}

.landing-page-cta {
  padding: 1em 0 1em 5em;
  background-color: #f3f3f3;
}
.landing-page-cta .prompt {
  display: inline;
  font-family: ITCAvantGardePro-Md, sans-serif;
  font-weight: bold;
  color: var(--mid-blue);
}
.landing-page-cta .subscriber-email {
  padding: 4px 6px 7px;
  width: 20em;
  border-radius: 6px 0 0 6px;
  background-color: #e7e7e7;
}
.landing-page-cta .subscribe-button {
  font-family: ITCAvantGardePro-Md, sans-serif;
  padding: 4px 22px 6px 23px;
  border-radius: 0 6px 6px 0;
  background-color: var(--turquoise);
  color: var(--white-two);
}

.join-the-movement {
  font-size: 1.4em;
  height: 5em;
}
.join-the-movement .prompt {
  display: inline-block;
  font-family: ITCAvantGardePro-Md, sans-serif;
  color: var(--turquoise);
}
.join-the-movement .subscriber-email {
  width: 17em;
  height: 2em;
  background-color: #f0f0f0;
  color: #7f7e7e;
  padding-left: 0.5em;
}
.join-the-movement button {
  padding: 0 1em 0 0;
  width: 2em;
  height: 2em;
}

.partnership {
  padding: 1em;
}
.partnership .prompt {
  font-family: ITCAvantGardePro-Md, sans-serif;
  font-weight: bold;
  font-size: 2.3em;
  color: var(--mid-blue);
}
.partnership p {
  font-family: FiraSans, sans-serif;
  font-size: 1.6em;
  color: var(--greyish-brown);
}
.partnership .subscriber-email {
  width: 16.5em;
  height: 3em;
  border-radius: 6px 0 0 6px;
  padding-left: 1em;
}
.partnership .subscribe-button {
  width: 10em;
  height: 3em;
  border-radius: 0 6px 6px 0;
  background-color: var(--turquoise);
  color: var(--white-two);
}

.subscriber-email {
  border: none;
  margin-left: 20px;
}

.subscribe-button {
  border: none;
}

textarea:focus,
input:focus,
*:focus {
  outline: none;
}

/*
 * iPad-friendly CSS
 */
@media screen and (max-width: 992px) {
  .landing-page-cta {
    padding-left: 1em;
  }
  .landing-page-cta .subscriber-email {
    width: 15em;
  }

  .partnership {
    padding: 0;
  }
  .partnership .prompt {
    font-size: 1.8em;
  }
  .partnership .subscriber-email {
    width: 11.5em;
  }

  .join-the-movement {
    font-size: 1em;
  }
  .join-the-movement .subscriber-email {
    width: 16em;
  }
}

/*
 * Mobile-friendly CSS
 */
@media screen and (max-width: 600px) {
  .landing-page-cta {
    padding-left: 0.5em;
  }

  .partnership .prompt {
    font-size: 1.5em;
  }
  .landing-page p {
    font-size: 1em !important;
  }

  .landing-page-cta .subscriber-email,
  .landing-page-cta .subscribe-button,
  .partnership .subscriber-email,
  .partnership .subscribe-button {
    border-radius: 6px 6px 6px 6px;
    margin-left: 0px;
  }
  .landing-page-cta .subscribe-button,
  .partnership .subscribe-button {
    margin-top: 0.3em;
  }

  .partnership .subscriber-email {
    width: 17em;
  }

  .join-the-movement {
    font-size: 1.1em;
    height: 5em;
  }
  .join-the-movement .subscriber-email {
    width: 12em;
  }
}
